import { FC, useLayoutEffect, useState } from 'react'
import ModalBase from '../../components/ModalBase'
import { makeStyles } from '@mui/styles'
import { Box, Grid, Stack, Tab, Theme, Typography, useTheme } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import CustomTextField from '../../components/CustomTextField'
import CustomSwitch from '../../components/CustomSwitch'
import CustomButton from '../../components/CustomButton'
import { NotificationManager } from '../../services/api/NotificationManager'
import { GetNotificationSettingsResponse } from '../../shared/payloads/notificationSettingsPayloads'
import { showFlashMessageWithTimeout } from '../../services/actions/flashMessage-actions'
import { useDispatch } from 'react-redux'
import DOMPurify from 'dompurify'
import NotificationPreview from '../../components/NotificationPreview'
import HelpButton from '../../components/HelpButton'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import IconSelect from '../../components/NotificationEditComponents/IconSelect'
import BorderSelect from '../../components/NotificationEditComponents/BorderSelect'
import { NotificationDefaultSettings } from '../../types/notification-default-settings'
import CustomColorPicker from '../../components/NotificationEditComponents/CustomColorPicker'
import TextEditor from '../../components/NotificationEditComponents/TextEditor'
import { convertToRaw, EditorState, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { useTranslation } from 'react-i18next'
import { v4 } from 'uuid'

interface IndividualNotificationModalProps {
    isOpen: boolean
    onClose: () => void
    createdNotificationId: string
    data?: GetNotificationSettingsResponse
}

type TabType = 'rules' | 'design'

const notificationStyleId = v4()

const IndividualNotificationModal: FC<IndividualNotificationModalProps> = ({ isOpen, onClose, createdNotificationId, data }) => {
    const classes = useStyles()
    const theme = useTheme()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const [selectedTab, setSelectedTab] = useState<TabType>('rules')

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [notificationName, setNotificationName] = useState<string>(data?.name ? data?.name : '')
    const [notificationText, setNotificationText] = useState<EditorState>(
        data?.text
            ? EditorState.createWithContent(
                  ContentState.createFromBlockArray(htmlToDraft(data.text).contentBlocks, htmlToDraft(data.text).entityMap)
              )
            : EditorState.createEmpty()
    )

    const [buttonName, setButtonName] = useState<string>(data?.linkText ? data?.linkText : '')
    const [withLink, setWithLink] = useState<boolean>(data?.addLink !== undefined ? data?.addLink : true)
    const [linkInNewTab, setLinkInNewTab] = useState<boolean>(
        data?.openLinkInNewWindow !== undefined ? data?.openLinkInNewWindow : true
    )
    const [withRepeat, setWithRepeat] = useState<boolean>(
        data?.repeatNotification !== undefined ? data?.repeatNotification : true
    )
    const [link, setLink] = useState<string>(data?.link ? data?.link : '')
    const [linkAsButton, setLinkAsButton] = useState<boolean>(data?.linkAsButton !== undefined ? data?.linkAsButton : true)

    const [notificationHtml, setNotificationHtml] = useState<string>('')

    const [backgroundColor, setBackgroundColor] = useState<string | null>(
        data?.notificationVisualSettings?.backgroundColor || null
    )
    const [textColor, setTextColor] = useState<string | null>(data?.notificationVisualSettings?.textColor || null)
    const [iconBackgroundColor, setIconBackgroundColor] = useState<string | null>(
        data?.notificationVisualSettings?.iconBackgroundColor || null
    )
    const [iconColor, setIconColor] = useState<string | null>(data?.notificationVisualSettings?.iconColor || null)
    const [linkTextColor, setLinkTextColor] = useState<string | null>(data?.notificationVisualSettings?.linkTextColor || null)
    const [linkButtonBackgroundColor, setLinkButtonBackgroundColor] = useState<string | null>(
        data?.notificationVisualSettings?.linkButtonBackgroundColor || null
    )
    const [borderRadius, setBorderRadius] = useState<number | null>(data?.notificationVisualSettings?.roundness || null)
    const [icon, setIcon] = useState<string | null>(data?.notificationVisualSettings?.icon || null)

    const onCloseAndClearInputs = () => {
        setNotificationName('')
        setNotificationText(EditorState.createEmpty())
        setButtonName('')
        setWithLink(true)
        setLinkInNewTab(true)
        setWithRepeat(true)
        setLink('')
        setLinkAsButton(true)
        onClose()
    }

    useLayoutEffect(() => {
        const createNotificationPreviewHtml = async () => {
            const result = await window.getNotificationPreview(
                {
                    campaign: {
                        notificationVisualSettings: {
                            roundness: 1,
                        },
                    },
                    settings: {
                        type: 'individual',
                        notificationVisualSettings: {
                            backgroundColor: backgroundColor ?? '',
                            textColor: textColor ?? '',
                            iconBackgroundColor: iconBackgroundColor ?? '',
                            iconColor: iconColor ?? '',
                            icon: icon ?? '',
                            roundness: borderRadius ?? 0,
                            linkTextColor: linkTextColor ?? '',
                            linkButtonBackgroundColor: linkButtonBackgroundColor ?? '',
                        },
                    },
                },
                'individual',
                undefined,
                draftToHtml(convertToRaw(notificationText.getCurrentContent())),
                undefined,
                'cs',
                undefined,
                undefined,
                undefined,
                undefined,
                withLink,
                buttonName,
                linkAsButton,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                notificationStyleId
            )
            // Transforms object htmldivelement to pure string
            const cleanHtmlString = DOMPurify.sanitize(result, {
                USE_PROFILES: { html: true, svg: true },
            })
            setNotificationHtml(cleanHtmlString)
        }

        createNotificationPreviewHtml()
    })

    const handleNotificationNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNotificationName(event.target.value)
    }

    // const handleNotificationTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setNotificationText(event.target.value)
    // }

    const handleButtonNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value

        if (newValue.length <= 33) setButtonName(newValue)
    }

    const handleLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLink(event.target.value)
    }

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setSelectedTab(newValue as TabType)
    }

    const toggleWithLink = () => setWithLink(!withLink)

    const toggleLinkInNewTab = () => setLinkInNewTab(!linkInNewTab)

    const toggleWithRepeat = () => setWithRepeat(!withRepeat)

    const toggleLinkAsButton = () => setLinkAsButton(!linkAsButton)

    const activeColor = theme.palette.natural.t1
    const notActiveColor = theme.palette.natural.t2

    const onSave = async () => {
        if (withLink && buttonName.length === 0) {
            showFlashMessageWithTimeout(dispatch, t('popups.setButtonText'), 'error')
            return
        }

        if (withLink && !link.startsWith('http')) {
            showFlashMessageWithTimeout(dispatch, t('popups.missingHttp'), 'error')
            return
        }

        try {
            setIsLoading(true)
            const _notificationManager = NotificationManager.getManager()
            const body = {
                id: createdNotificationId,
                isActive: true,
                repeatNotification: withRepeat,
                addLink: withLink,
                name: notificationName,
                text: draftToHtml(convertToRaw(notificationText.getCurrentContent())),
                linkAsButton: withLink ? linkAsButton : false,
                openLinkInNewWindow: withLink ? linkInNewTab : false,
                linkText: withLink ? buttonName : undefined,
                link: withLink ? link : undefined,

                notificationVisualSettings: {
                    backgroundColor: backgroundColor ?? '',
                    textColor: textColor ?? '',
                    iconBackgroundColor: iconBackgroundColor ?? '',
                    iconColor: iconColor ?? '',
                    icon: icon ?? '',
                    roundness: borderRadius ?? 0,
                    linkTextColor: linkTextColor ?? '',
                    linkButtonBackgroundColor: linkButtonBackgroundColor ?? '',
                },
            }
            if (notificationName === '' || notificationText.getCurrentContent().isEmpty()) {
                showFlashMessageWithTimeout(dispatch, t('popups.addNameAndText'), 'error')
                setIsLoading(false)
                return
            }

            await _notificationManager.updateNotificationSettingsAsync(body)
            setIsLoading(false)
            onCloseAndClearInputs()
        } catch (e: any) {
            setIsLoading(false)
            showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
        }
    }

    return (
        <ModalBase isOpen={isOpen} onClose={onCloseAndClearInputs} center>
            <Stack
                direction='column'
                bgcolor={theme.palette.natural.t4}
                p={3}
                overflow='auto'
                sx={{
                    width: { xs: '80%', md: 900 },
                    maxHeight: '90vh',
                }}
                boxShadow={theme.palette.main.shadow.secondary}
                borderRadius={2}
            >
                <Stack direction='row' justifyContent='space-between'>
                    <Typography color={theme.palette.main.secondary.u1} fontWeight='bold' fontSize={{ xs: 18, md: 24 }}>
                        {t('campaign.notifications.individual.title')}
                    </Typography>
                    <CloseIcon style={{ color: theme.palette.natural.t2, cursor: 'pointer' }} onClick={onCloseAndClearInputs} />
                </Stack>
                <NotificationPreview notificationHtml={notificationHtml} />
                <TabContext value={selectedTab}>
                    <TabList
                        TabIndicatorProps={{
                            style: {
                                background: theme.palette.main.primary.o,
                                height: 3,
                            },
                        }}
                        onChange={handleChange}
                    >
                        <Tab
                            disableRipple
                            className={classes.tab}
                            style={{
                                color: selectedTab === 'rules' ? activeColor : notActiveColor,
                            }}
                            label={t('campaign.notifications.settings.displayRules.title')}
                            value='rules'
                        />
                        <Tab
                            disableRipple
                            className={classes.tab}
                            style={{
                                color: selectedTab === 'design' ? activeColor : notActiveColor,
                            }}
                            label={t('campaign.notifications.settings.design.title')}
                            value='design'
                        />
                        <Box className={classes.tabsDivider} />
                    </TabList>
                    <TabPanel value='rules' style={{ padding: 0 }}>
                        <div style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}>
                            <HelpButton link='https://www.overenyweb.cz/napoveda/nastaveni-notifikaci/vlastni-notifikace/' />
                        </div>
                        <Grid container columnSpacing={2}>
                            <Grid item xs={12} md={6}>
                                <Stack>
                                    <Typography className={classes.sectionTitle} style={{ marginBottom: 16 }}>
                                        {t('campaign.notifications.settings.displayRules.title')}
                                    </Typography>
                                    <CustomTextField
                                        fullWidth
                                        label={t('campaign.notifications.settings.displayRules.nameNotification') ?? ''}
                                        value={notificationName}
                                        onChange={handleNotificationNameChange}
                                    />
                                    <Box className={classes.repeatSwitchContainer}>
                                        <Typography className={classes.switchLabel}>
                                            {t('campaign.notifications.settings.displayRules.repeatNotification')}
                                        </Typography>
                                        <CustomSwitch checked={withRepeat} onSwitch={() => toggleWithRepeat()} disabled={false} />
                                    </Box>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={2}>
                                    <Typography className={classes.sectionTitle}>
                                        {t('campaign.notifications.settings.displayRules.text')}
                                    </Typography>
                                    <TextEditor value={notificationText} onChange={setNotificationText} />
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack direction='row' alignItems='center' mb={1}>
                                    <Typography className={classes.sectionTitle} style={{ marginRight: 24 }}>
                                        {t('campaign.notifications.settings.displayRules.individual.link')}
                                    </Typography>
                                    <CustomSwitch checked={withLink} onSwitch={toggleWithLink} disabled={false} />
                                </Stack>
                                {!withLink && (
                                    <Typography style={{ fontSize: 14, color: theme.palette.natural.t1 }}>
                                        {t('campaign.notifications.settings.displayRules.individual.removedLinkDescription')}
                                    </Typography>
                                )}
                                {withLink && (
                                    <>
                                        <CustomTextField
                                            fullWidth
                                            label={`${t('campaign.notifications.settings.displayRules.individual.buttonName')} ${
                                                linkAsButton
                                                    ? t('campaign.notifications.settings.displayRules.individual.button')
                                                    : t('campaign.notifications.settings.displayRules.individual.link')
                                            }`}
                                            value={buttonName}
                                            onChange={handleButtonNameChange}
                                            style={{ marginBottom: 8 }}
                                        />
                                        <CustomTextField
                                            fullWidth
                                            label='URL'
                                            value={link}
                                            onChange={handleLinkChange}
                                            style={{ marginBottom: 4 }}
                                        />
                                    </>
                                )}
                                {withLink && (
                                    <>
                                        <Box className={classes.switchContainer}>
                                            <Typography className={classes.titleText}>
                                                {t('campaign.notifications.settings.displayRules.individual.buttonAsLink')}
                                            </Typography>
                                            <CustomSwitch checked={linkAsButton} onSwitch={toggleLinkAsButton} />
                                        </Box>
                                    </>
                                )}
                                {!withLink && (
                                    <>
                                        <Box className={classes.divider} />
                                        <Box className={classes.repeatSwitchContainer}>
                                            <Typography className={classes.switchLabel}>
                                                {t('campaign.notifications.settings.displayRules.repeatNotification')}
                                            </Typography>
                                            <CustomSwitch
                                                checked={withRepeat}
                                                onSwitch={() => toggleWithRepeat()}
                                                disabled={false}
                                            />
                                        </Box>
                                    </>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6} mt={5.5}>
                                {withLink && (
                                    <>
                                        <Stack direction='row' alignItems='center' spacing={1} mb={1}>
                                            <Typography className={classes.titleText} width={150}>
                                                {t('campaign.notifications.settings.design.textColour')}:
                                            </Typography>
                                            <CustomColorPicker
                                                color={linkTextColor}
                                                onChange={setLinkTextColor}
                                                defaultColor={
                                                    linkAsButton
                                                        ? NotificationDefaultSettings.ButtonTextColor
                                                        : NotificationDefaultSettings.LinkTextColor
                                                }
                                            />
                                        </Stack>
                                        <Box className={classes.switchContainer}>
                                            <Typography className={classes.titleText}>
                                                {t('campaign.notifications.settings.displayRules.individual.openLinkInNewWindow')}
                                            </Typography>
                                            <CustomSwitch checked={linkInNewTab} onSwitch={toggleLinkInNewTab} />
                                        </Box>
                                    </>
                                )}
                                {linkAsButton && (
                                    <Stack direction='row' alignItems='center' spacing={1} mb={1} mt={0.5}>
                                        <Typography className={classes.titleText} width={150}>
                                            {t('campaign.notifications.settings.displayRules.individual.buttonColor')}
                                        </Typography>
                                        <CustomColorPicker
                                            color={linkButtonBackgroundColor}
                                            onChange={setLinkButtonBackgroundColor}
                                            defaultColor={NotificationDefaultSettings.ButtonBackgroundColor}
                                        />
                                    </Stack>
                                )}
                            </Grid>
                        </Grid>
                        <Box className={classes.buttonsContainer}>
                            <CustomButton
                                size='medium'
                                title={t('save')}
                                style={{ width: 88 }}
                                isLoading={isLoading}
                                isDisabled={isLoading}
                                onClick={onSave}
                            />
                        </Box>
                    </TabPanel>
                    <TabPanel value='design' style={{ padding: 0 }}>
                        <Typography style={{ marginTop: 16 }} mb={1} color={theme.palette.natural.t1}>
                            {t('campaign.notifications.settings.design.description')}
                        </Typography>
                        <Grid container columnSpacing={3}>
                            <Grid item xs={12} md={6}>
                                <Stack direction='row' alignItems='center'>
                                    <Typography className={classes.sectionTitle} width={300}>
                                        {t('campaign.notifications.settings.design.backgroundColour')}
                                    </Typography>

                                    <CustomColorPicker
                                        color={backgroundColor}
                                        onChange={setBackgroundColor}
                                        defaultColor={NotificationDefaultSettings.BackgroundColor}
                                    />
                                </Stack>
                                <Stack direction='row' alignItems='center'>
                                    <Typography className={classes.sectionTitle} width={300}>
                                        {t('campaign.notifications.settings.design.textColour')}
                                    </Typography>

                                    <CustomColorPicker
                                        color={textColor}
                                        onChange={setTextColor}
                                        defaultColor={NotificationDefaultSettings.TextColor}
                                    />
                                </Stack>
                                <Stack direction='row' alignItems='center'>
                                    <Typography className={classes.sectionTitle} width={300}>
                                        {t('campaign.notifications.settings.design.iconBackgroundColour')}
                                    </Typography>

                                    <CustomColorPicker
                                        color={iconBackgroundColor}
                                        onChange={setIconBackgroundColor}
                                        defaultColor={NotificationDefaultSettings.IconBackgroundColor}
                                    />
                                </Stack>
                                <Stack direction='row' alignItems='center'>
                                    <Typography className={classes.sectionTitle} width={300}>
                                        {t('campaign.notifications.settings.design.iconColour')}
                                    </Typography>

                                    <CustomColorPicker
                                        color={iconColor}
                                        onChange={setIconColor}
                                        defaultColor={NotificationDefaultSettings.IconColor}
                                    />
                                </Stack>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Typography className={classes.sectionTitle}>
                                    {t('campaign.notifications.settings.design.roundedCorners')}
                                </Typography>

                                <BorderSelect
                                    selectedBorderValue={borderRadius}
                                    onChange={setBorderRadius}
                                    defaultBorderValue={Number(NotificationDefaultSettings.BorderRoundness)}
                                />

                                <Typography className={classes.sectionTitle} mt={1}>
                                    {t('campaign.notifications.settings.design.icon')}
                                </Typography>

                                <IconSelect
                                    selectedIcon={icon}
                                    onChange={setIcon}
                                    defaultIcon={NotificationDefaultSettings.IndividualIcon}
                                />

                                <Stack direction='row' justifyContent='flex-end' mt={1}>
                                    <CustomButton
                                        style={{ width: 132 }}
                                        size='medium'
                                        type='secondary'
                                        title={t('back')}
                                        onClick={() => setSelectedTab('rules')}
                                    />
                                    <CustomButton
                                        style={{ width: 132, marginLeft: 24 }}
                                        size='medium'
                                        type='primary'
                                        title={t('save')}
                                        isLoading={isLoading}
                                        onClick={onSave}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </TabPanel>
                </TabContext>
            </Stack>
        </ModalBase>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    tabsDivider: {
        width: 262,
        height: 3,
        backgroundColor: theme.palette.natural.t3,
        position: 'absolute',
        bottom: 0,
    },
    tab: {
        textTransform: 'none',
        fontSize: 16,
    },
    container: {
        flex: 1,
        width: 906,
        backgroundColor: theme.palette.natural.t4,
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 24,
        paddingBottom: 32,
        borderRadius: 8,
        boxShadow: theme.palette.main.shadow.secondary,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    titleContainer: {
        height: 34,
        flex: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 16,
    },
    title: {
        fontSize: 28,
        fontWeight: 'bold',
        color: theme.palette.main.secondary.u1,
    },
    sectionTitle: {
        fontSize: 18,
        color: theme.palette.main.primary.o,
        fontWeight: 'bold',
    },
    switchLabel: {
        color: theme.palette.natural.t1,
        fontSize: 16,
    },
    optionsContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
    },
    buttonsContainer: {
        height: 44,
        flex: 1,
        display: 'flex',
        flexDirection: 'row-reverse',
    },
    titleSwitchContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 42,
        marginBottom: 16,
    },
    switchContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    titleText: {
        fontSize: 16,
        color: theme.palette.natural.t1,
    },
    divider: {
        height: 1,
        flex: 1,
        marginTop: 24,
        borderTop: `3px dashed ${theme.palette.main.secondary.d1}`,
    },
    repeatSwitchContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 8,
    },
}))

export default IndividualNotificationModal
