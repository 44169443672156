import { Grid, Stack } from '@mui/material'
import React from 'react'
import { iconPathsMap } from '../../assets/icons/iconPaths'
import CustomButton from '../CustomButton'
import IconComponent from './IconComponent'

interface IconSelectProps {
    selectedIcon: string | null
    onChange: (icon: string | null) => void
    defaultIcon?: string
}

const IconSelect: React.FC<IconSelectProps> = ({ selectedIcon, onChange, defaultIcon }: IconSelectProps) => {
    if (!iconPathsMap) return null

    return (
        <Stack
            direction={{
                xs: 'column',
                md: 'row',
            }}
            spacing={1}
            justifyContent='space-between'
            alignItems='center'
            pt={1}
        >
            <Grid
                container
                overflow='auto'
                maxHeight='260px'
                maxWidth={{
                    xs: '400px',
                    md: '320px',
                }}
            >
                {Array.from(iconPathsMap).map(([key, value]) => (
                    <Grid item xs={1.2} md={2}>
                        <IconComponent
                            isSelected={key === (selectedIcon || defaultIcon)}
                            iconPath={value}
                            onClick={() => onChange(key)}
                        />
                    </Grid>
                ))}
            </Grid>
            {selectedIcon && (
                <CustomButton
                    onClick={() => onChange(null)}
                    title='Reset'
                    size='medium'
                    type='secondary'
                    style={{
                        width: 100,
                    }}
                />
            )}
        </Stack>
    )
}

export default IconSelect
