export const hu = {
    sidePanel: {
        campaign: 'Kampány',
        connection: 'Összekapcsolás',
        settings: 'Beállítások',
        infinite: 'korlátozások nélkül',
    },
    noTariffBanner: {
        title: 'Az Ön fiókja nem tartalmaz semmilyen aktív díjcsomagot',
        description:
            'Aktív díjcsomag nélkül nem tudunk a konverziós arány növelése érdekében értesítéseket megjeleníteni a weboldalán.',
        button1: 'Díjcsomag aktiválása',
        button2: 'az első 14 napra ingyen',
    },
    apiKeys: 'API kulcsok',
    login: 'Bejelentkezés',
    monthlyCredits: 'Havi kreditek',
    portableCredits: 'Hordozható kreditek',
    help: 'Súgó',
    reportProblem: 'Hiba bejelentése',
    saveChanges: 'Változtatások mentése',
    reset: 'Reset',
    defaultSettings: 'Kezdeti beállítások',
    addKey: 'Kulcs hozzáadása',
    back: 'Vissza',
    close: 'Bezárás',
    delete: 'Törlés',
    yes: 'Igen',
    verified: 'Ellenőrizve',
    notVerified: 'Nem ellenőrizve',
    connected: 'Összekapcsolva',
    active: 'Aktív',
    inactive: 'Inaktív',
    check: 'Ellenőrzés',
    add: 'Hozzáadás',
    edit: 'Módosítás',
    save: 'Mentés',
    continue: 'Tovább',
    userIsNotLoggedIn: 'A felhasználó nincs bejelentkezve',
    logout: 'Kijelentkezés',
    loggedOut: {
        title: 'Kijelentkezett',
        description: 'Bezárhatja az oldalt',
        close: 'Oldal bezárása',
    },
    popups: {
        deletionWasSuccessful: 'Az eltávolítás sikeres volt.',
        saveWasSuccessful: 'Sikeresen elmentve',
        visualChanged: 'Sikeresen beállított kinézet',
        notificationAdded: 'Az értesítés hozzáadásra került.',
        setButtonText: 'Gomb szövegének beállítása.',
        missingHttp: 'Az URL elején "http"-t vagy "https"-nek kell lennie."',
        addNameAndText: 'Adja hozzá az értesítés nevét és a szöveget.',
        textMustBeFilled: 'Az értesítés szövegét ki kell tölteni',
        connectionMissing: 'Legalább egy elemet teljesítene kell a Párosításban."',
        added: 'Hozzáadva.',
        fillAllFields: 'Töltse ki az összes mezőt',
        fillApiKey: 'Adja meg az API-kulcsot',
        notVerified: 'Nem ellenőrzött',
        changesSaved: 'Sikeresen elmentett módosítások.',
        changeSubscriptionDisabled: 'Az előfizetési feltételek megváltoztatása tilos',
        subscriptionChangedTo: 'Előfizetés módosítva erre: ',
        subscriptionCanceled: 'Előfizetés sikeresen törölve',
        nameIsEmpty: 'A név nem lehet üres',
        cannotCreateNotification: 'Nem sikerült értesítést létrehozni.',
    },
    campaign: {
        addFirstCampaign: 'Adja hozzá az első kampányát',
        setUpFirstCampaign: 'Állítsa be az első kampányát, és kezdjen el pénzt keresni.',
        addCampaign: 'Kampány hozzáadása',
        addNotification: 'Értesítés hozzáadása',
        deleteCampaign: 'Kampány törlése?',
        table: {
            name: 'Név',
            creationDate: 'Létrehozva',
            notificationCount: 'Értesítés',
            status: 'Állapot',
            delete: 'Törlés',
        },
        settings: {
            title: 'Beállítások',
            campaignName: 'Kampány neve',
            newCampaign: 'Új kampány',
            whereWillCampaignBeShown: 'Hol jelenik meg a kampány',
            url: {
                startsWith: 'Kezdőbetű',
                includes: 'Benne található',
                equals: 'Megegyezik',
            },
            includes: 'Tartalmaz',
            exclude: 'Kizár (nem kötelező)',
            addCondition: 'Feltétel hozzáadása',
        },
        design: {
            title: 'Kinézet',
            notificationsDesign: 'Értesítések kinézete',
            designDescription:
                'Egész kampányra vonatkozó beállítások, egy konkrét értesítés kinézete maga az értesítés beállításaiban módosítható.',
            computer: 'Számítógép',
            mobile: 'Mobil',
            position: 'Pozíció',
            downLeftCorner: 'Bal alsó sarok',
            downRightCorner: 'Jobb alsó sarok',
            upLeftCorner: 'Bal felső sarok',
            upRightCorner: 'Jobb felső sarok',
            down: 'Lent',
            up: 'Fent',
            animations: 'Animáció',
            playAnimation: 'Animáció lejátszása',
            animationSettings: {
                showAfter: 'Értesítés megjelenítése ennyi idő elteltével',
                showDelay: 'Megjelenítés időtartama',
                delayBetween: 'Értesítések közötti késleltetés',
                showSpeed: 'Megjelenítés gyorsasága',
                second: 'másodperc',
                seconds: 'másodpercekben',
            },
        },
        notifications: {
            title: 'Értesítés',
            noNotifications: 'Egyelőre nincsenek értesítései',
            addNewNotification: 'Értesítés hozzáadása',
            table: {
                type: 'Típus',
                preview: 'Előnézet',
                status: 'Állapot',
            },
            settings: {
                displayRules: {
                    title: 'Megjelenítési szabályok',
                    text: 'Szöveg',
                    notificationText: 'Értesítés szövege',
                    variant: 'Változat',
                    choseVariant: 'Változat kiválasztása',
                    days: 'nap',
                    show: 'Megjeleníti',
                    notificationName: 'Értesítés szövege',
                    nameNotification: 'Adjon nevet az értesítésnek',
                    repeatNotification: 'Értesítések ismétlései',
                    fillNotificationText: 'Adja meg az értesítés szövegét',
                    actualVisitors: {
                        nameVisitors: 'Nevezze meg a látogatókat',
                        visitors: 'egyedi látogató',
                        maxVisitors: 'Megtekintéshez szükséges minimális egyedi látogatók száma',
                    },
                    lastOrders: {
                        maxLast: 'Legfeljebb az utolsó',
                        maxToShow: 'Megjelenítéshez a minimális mennyiség',
                        whenAtLeast: 'Értesítés csak akkor, ha legalább',
                        ordersLast: 'Megrendelés az utolsó',
                        haveOrderedPlaceholder: 'már rendelt. És Ön?',
                        showOwnOrders: 'Saját megrendelések megmutatása a látogatóknak',
                        notRecoginzed: 'Fel nem ismert nevek, mint "Ügyfél"',
                        orders: 'megrendelés',
                    },
                    lastRegistrations: {
                        registrations: 'regisztrációval',
                        notRecoginzed: 'Fel nem ismert nevek, mint "Látogató"',
                        registrationsLast: 'Regisztráció az utolsó',
                        showOwnRegistrations: 'Saját regisztráció bemutatása a látogatóknak',
                        isRegistredPlaceholder: 'befejezte a regisztrációt',
                    },
                    visitorSummary: {
                        visitors: 'egyedi látogatók',
                        registrations: 'regisztrációval',
                        orders: 'megrendelés',
                        visitors2: 'egyedi látogató',
                        last: 'az utolsó',
                        minNumber: 'Minimális darabszám',
                        toShow: 'a megjelenítéshez',
                    },
                    individual: {
                        link: 'Link',
                        button: 'Gomb',
                        buttonName: 'Gomb elnevezése',
                        url: 'URL',
                        buttonAsLink: 'Link gombként',
                        textColor: 'Szöveg színe',
                        openLinkInNewWindow: 'Link megnyitása új ablakban',
                        buttonColor: 'Gomb színe',
                        removedLinkDescription: 'A link eltávolítása hosszabb értesítési szöveget tesz lehetővé.',
                    },
                    webName: 'Weboldal neve',
                    apiKey: 'API kulcs',
                    heureka: {
                        title: 'Összekapcsolás a Heurekával',
                        description: 'Az értesítés aktiválásához Heureka API-kulcs szükséges. Szeretné hozzáadni az API-kulcsot?',
                        heurekaApiKey: 'Heureka API kulcs',
                        country: 'Ország',
                        stars: 'csillaggal',
                        reviewsWithRating: 'Értékelések több, mint',
                        maxReviews: 'Értékelések minimális száma a megjelenítéshez',
                        howToAddApiKey: {
                            title: 'Heureka kulcs hozzáadása',
                            first: 'A Heureka-fiókjában válassza ki a felső menüből a webáruház Adminisztráció menüpontját.',
                            second: 'A bal oldali menüben válassza ki a Vásárlók által ellenőrzött menüpontot.',
                            third: 'Másolja ki a titkos kulcsot, és illesszék be az API-kulcs mezőbe.',
                            thirdHelp:
                                'A kulcs akkor érhető el, ha elegendő értékeléssel rendelkezik, és megfelel a "Vásárlók által ellenőrzött" feltételeknek.',
                            fourth: 'Ellenőrizze az állapotot',
                        },
                    },
                    zbozi: {
                        title: 'Összekapcsolás a Zboží.cz-vel',
                        description:
                            'Az értesítés aktiválásához a Zboží.cz API-kulcsára van szükség. Szeretné hozzáadni az API-kulcsot?',
                        howToAddKey: 'Zboží.cz API-kulcs hozzáadása',
                        shopId: 'Létesítmény azonosító',
                        storeReview: 'üzlet értékelése',
                        productReview: 'termék értékelése',
                        maxReviews: 'Értékelések minimális száma a megjelenítéshez',
                        howToAddApiKey: {
                            title: 'Zboží.cz kulcs hozzáadása',
                            first: 'Zboží.cz fiókjában válassza ki a főmenüben az Létesítmény könyvjelzőt.',
                            second: 'Másolja ki a kiválasztott webáruház azonosítóját, és illessze be az létesítmény-azonosító mezőbe.',
                            third: 'A Létesítmény adminisztrálása oldalon keresse meg a bal oldali menüben az API-kezelés menüpontot, és hozzon létre egy API-kulcsot.',
                            thirdHelp:
                                'Itt a 2. lépésben kapott létesítmény-azonosítóra lesz szüksége. Illessze be a generált API-kulcsot az API-kulcs mezőbe.',
                            fourth: 'Ellenőrizze az állapotot.',
                        },
                    },
                    spolehlivaRecenze: {
                        title: 'Spolehlivé Recenze kulcs hozzáadása',
                        description:
                            'Az értesítés aktiválásához be kell jelentkezni a(z) Spolehlivé Recenze. Be szeretne jelentkezni?',
                        howToAddApiKey: {
                            title: 'Hogyan jelentkezzen be a(z) Spolehlivé Recenze',
                            first: 'Másolja ki a shopid-et és a jelszót a fiókjából a(z) Spolehlivé Recenze.',
                            second: 'Írja be a következő információkat a megfelelő fenti mezőkbe.',
                            third: 'Ellenőrizze az állapotot.',
                        },
                    },
                },
                pairing: {
                    title: 'Párosítás',
                    collectData: 'Adatrögzítés',
                    addCondition: 'Feltétel hozzáadása',
                    cantEdit: 'Nem szerkeszthető',
                    pairingCondition: 'Párosítási szabály',
                    campaignCollects: 'A kampány minden egyes oldal látogatóit külön-külön rögzíti.',
                    celectedDomain: 'Kiválasztott domain',
                    pageVisitors: 'Az egyes oldalak látogatói',
                    domainVisitors: 'Az egész domain látogatóinak összefoglalásai',
                },
                design: {
                    title: 'Kinézet',
                    description:
                        'Az alapértelmezett kinézet az egész kampányra van beállítva, itt testre szabhatja egy adott értesítéshez.',
                    backgroundColour: 'Háttérszín',
                    textColour: 'Szöveg színe',
                    iconBackgroundColour: 'Ikon háttérszíne',
                    iconColour: 'Ikon színe',
                    roundedCorners: 'Sarkok lekerekítése',
                    icon: 'Ikon',
                },
            },
            deleteModal: {
                title: 'Értesítések törlése',
                description: 'Minden adat törlésre kerül',
            },
            lastOrders: {
                title: 'Utolsó megrendelések',
                description: 'Heti 10 vagy több megrendeléssel rendelkező webáruházak számára ajánlott.',
                previewText1: 'Kassai József',
                previewText2: 'befejezte a megrendelést',
            },
            lastRegistrations: {
                title: 'Utolsó beregisztrálás',
                description: 'Ajánlott kereslet- és a kapcsolatgyűjtő oldalak számára.',
                previewText1: 'Kassai József',
                previewText2: 'befejezte a regisztrációt',
            },
            actualVisitors: {
                title: 'Látogatottsági arány - aktuális statisztikák',
                description: 'Legalább napi 1 000 egyedi látogatóval rendelkező oldalak számára ajánlott.',
                previewText1: 'egyedi látogató',
            },
            visitorSummary: {
                title: 'Látogatottság - összefoglaló statisztikák',
                description: 'Napi 1 000 egyedi látogatónál kevesebbel rendelkező oldalak számára ajánlott.',
                previewText1: 'egyedi látogató',
                previewText2: 'felhasználók',
            },
            orderSummary: {
                title: 'Látogatottság - összefoglaló statisztiká',
            },
            heureka: {
                title: 'Heureka - vélemények és értékelések',
                description:
                    'Olyan webáruházak számára ajánlott, amelyek pozitív szöveges értékelésekkel rendelkeznek a Heurekán',
                previewText1:
                    '12 pro max-szal összehasonlítva nagyra értékelem a szuper kamerát (csúcs 4k videók) és a fotókat - hatalmas különbség és előrelépés. Kiváló makró. Gyors és megbízható telefon. Nagy elégedettség. Gyönyörű szín.',
                previewText2: 'ajánlja',
            },
            zbozi: {
                title: 'Áruk - vélemények és értékelések',
                description:
                    'Olyan webáruházak számára ajánlott, amelyek pozitív szöveges értékelésekkel rendelkeznek a Zboží.cz oldalon',
                previewText1:
                    '12 pro max-szal összehasonlítva nagyra értékelem a szuper kamerát (csúcs 4k videók) és a fotókat - hatalmas különbség és előrelépés. Kiváló makró. Gyors és megbízható telefon. Nagy elégedettség. Gyönyörű szín.',
                previewText2: 'ajánlja',
            },
            individual: {
                title: 'Saját',
                description:
                    'Csatoljon tetszés szerinti szöveget az értesítéshez. Nagyszerű eszköz a kedvezmények, az ingyenes szállítás és a termékekkel, szolgáltatásokkal kapcsolatos egyéb előnyök közlésére.',
                previewText1: 'Iratkozzon fel a webáruházunkba, és 5% kedvezményt kap a vásárlására.',
                previewText2: 'Kapjon ajándékot',
            },
            shoptetLastOrders: {
                title: 'Utolsó Shoptet megrendelések',
                description: 'Exkluzív értesítések a Shoptet platformot használó webáruházak számára.',
                timeRange: 'Utolsó időtartományban',
                showRandom: 'Véletlenszerű rendelések megjelenítése az ',
                twoMonths: '2 hónap',
                screwdriver: 'Csavarhúzó',
            },
            shoptetCouponCode: {
                title: 'Shoptet kedvezménykód',
                description: 'Exkluzív értesítések a Shoptet platformot használó webáruházak számára.',
                info: 'Az értesítés feltételezi, hogy Ön rendelkezik kedvezmény kuponnal a shoptet platformon, amelyre vonatkozóan itt kitöltötte az adatokat.',
                code: 'Kedvezménykód',
                saleType: 'Kedvezmény értéke',
                saleIn: 'Kedvezmény a',
                saleOn: 'Kedvezmény',
                wholeSale: 'egész vásárlásra',
                sale10: 'KEDVEZMÉNY10',
                previewText: 'Aktiválja a "KEDVEZMÉNY10" kedvezménykódot, és kapjon 10 % kedvezményt Az egész vásárlásra.',
            },
            spolehlivaRecenzeShopRating: {
                title: 'Spolehlivé recenze - vélemények és értékelések',
                description:
                    'Olyan webáruházak számára ajánlott, amelyek pozitív szöveges értékelésekkel rendelkeznek a spolehliverecenze.cz oldalon',
            },
            spolehlivaRecenzeProductRating: {
                title: 'Spolehlivé recenze - vélemények és értékelések',
                description:
                    'Olyan webáruházak számára ajánlott, amelyek pozitív szöveges értékelésekkel rendelkeznek a spolehliverecenze.cz oldalon',
            },
        },
    },
    subscriptions: {
        title: 'Előfizetés',
        subTitle: 'Az Ön előfizetése',
        successPayment: 'Sikeres fizetés.',
        successPaymentDescription: 'A jóváírások pillanatok alatt megjelennek a számláján.',
        wantToChangeSubscription: 'Valóban meg kívánja változtatni az előfizetését',
        changeFrom: '-ről',
        changeTo: '-re?',
        portableCreditsDescription:
            'Ezek a kreditek nem járnak le. Ezek akkor kerülnek használatra, ha túllépte a havi hitelkeretet.',
        tarifs: {
            title: 'Díjcsomagok',
            monthly: 'Havonta',
            yearly: 'Évente',
            freeMonths: '2 hónap ingyen',
            viewsPerMonth: 'havi megtekintések száma',
            trial: 'próbaverzió - korlátozások nélkül',
            creditsRefresh: 'Kreditek megújítása: ',
            creditsExpire: 'A kreditek lejárata: ',
            subbscription: 'Előfizetés',
            trialVersion: 'próbaváltozat',
            trialVersionExpires: 'A próbaváltozat érvényessége lejár',
            cancelSubscription: 'Előfizetés megújításának törlése',
            nextPayment: 'A következő fizetésre automatikusan kerül sor',
            unlimitedVersion: 'Az alkalmazás korlátlan verziója aktív',
            choseSubscription: 'Díjcsomag kiválasztása',
            to: 'Eddig',
            monthlyViews: 'oldalmegtekintés havonta',
            pricesAreFinal: 'A feltüntetett árak véglegesek.',
            moreViews: 'Az Ön oldalainak még több megtekintése van havonta?',
            contactUsForCustom:
                'Személyre szabott díjakért lépjen kapcsolatba velünkSzemélyre szabott díjakért lépjen kapcsolatba velünk',
            notificationsWithStatistics: 'Értesítések látogatottsági statisztikákkal',
            notificationsWithLastOrdersAndRegistrations: 'Értesítés az utolsó megrendelésekről és regisztrációkról',
            notificationPersonalization: 'Értesítések személyre szabása',
            zboziAndHeureka: 'Értesítések Heureka és Zboží.cz értékelésekről',
            unlimitedNotifications: 'Korlátlan számú értesítés',
            unlimitedWebsites: 'Korlátlan számú weboldal',
            onlyOneWebsite: '(csak 1 weboldal)',
            activateFreeTrial: 'Ingyen 14 nap aktiválása',
            mostFavorite: 'Legnépszerűbb',
            selected: 'Kiválasztva',
            lite: {
                title: 'LITE',
                priceYearly: '39 Kč',
                priceMonthly: '47 Kč',
            },
            start: {
                title: 'START',
                priceYearly: '247 Kč',
                priceMonthly: '297 Kč',
            },
            standard: {
                title: 'STANDARD',
                priceYearly: '457 Kč',
                priceMonthly: '547 Kč',
            },
            premium: {
                title: 'PREMIUM',
                priceYearly: '833 Kč',
                priceMonthly: '997 Kč',
            },
        },
    },
    contact: {
        title: 'Kapcsolattartási adatok',
        basic: {
            title: 'Alapadatok',
            accountType: 'Számla típusa',
            personal: 'Személyes',
            business: 'Vállalkozói',
            name: 'Név',
            surname: 'Vezetéknév',
            phone: 'Telefonszám',
            dic: 'Adószám',
            ico: 'KSH-szám',
            companyName: 'Cégnév',
        },
        address: {
            title: 'Cím',
            street: 'Utca és hrsz.',
            city: 'Város',
            zip: 'Irányítószám',
            country: 'Ország',
            czechRepublic: 'Cseh Köztársaság',
            slovakia: 'Szlovákia',
            hungary: 'Magyarország',
        },
    },
    shoptetFirstTouchScreen: {
        title: 'A bővítmény telepítése sikeres volt.',
        description1:
            'Számos okos értesítést készítettünk az Ön webáruháza számára, amelyek a hasonló webáruházak esetében szerzett tapasztalataink szerint a leghatékonyabban növelik webáruháza megrendelései számát. Ezek automatikusan elindulnak. Közvetlenül az ingyenes próbaút aktiválása után.',
        description2:
            'Kattintson a gombra. Aktiválja az okos értesítéseket, és már csak figyelje, hogyan nőnek napról-napra a megrendelései.',
        button: 'Ingyenes 14 napos próbaverzió futtatása',
        error: 'A bejelentkezés sikertelen, jelentkezzen ki és próbálja meg újra.',
    },
}
