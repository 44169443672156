import { useEffect, useState } from 'react'
import { Box, Typography, FormGroup, FormControlLabel, Checkbox, useTheme, Theme, TextField } from '@mui/material'
import { UserManager } from '../../services/api/UserManager'
import PasswordField from '../../components/PasswordField'
import CustomTextField from '../../components/CustomTextField'
import CustomButton from '../../components/CustomButton'
import { makeStyles } from '@mui/styles'
import RegistrationPng from '../../assets/images/registration.png'
import { useDispatch, useSelector } from 'react-redux'
import { removeUserInfo, setUserInfo } from '../../services/actions/user-actions'
import { useNavigate } from 'react-router-dom'
import { Routes } from '../../navigation/navigation-types'
import { clearWebs } from '../../services/actions/web-actions'
import { showFlashMessageWithTimeout } from '../../services/actions/flashMessage-actions'
import CustomDropdown from '../../components/CustomDropdown'
import { GlobalVars } from '../../global'
import { Language, getLanguageFromString } from '../../shared/types/websiteTypes'
import { getAuthenticationStatus } from '../../services/selectors/user-selectors'
import { getUserWebs } from '../../services/selectors/web-selectors'
import { AUTH_LOCAL_STORAGE_KEY, parseJwtEncodedTokenAndGetReduxState } from '../../services/types/auth-types'
import { setAuthState } from '../../services/actions/auth-actions'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'

const MARGIN_SMALLER = 16
const MARGIN_HIGHER = 32

interface DecodedType {
    id: string
}

const RegistrationForm: React.FC = () => {
    const classes = useStyles()
    const theme = useTheme()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t, i18n } = useTranslation()

    const [agreeWithTermsAndConditions, setAgreeWithTermsAndConditions] = useState<boolean>(false)
    const [name, setName] = useState<string>('')
    const [phoneNumberCode, setPhoneNumberCode] = useState<string>('+420')
    const [phoneNumber, setPhoneNumber] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [repeatedPassword, setRepeatedPassword] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isEmailValid, setIsEmailValid] = useState<boolean>(false)
    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth)
    const isLoggedIn = useSelector(getAuthenticationStatus)
    const userWebs = useSelector(getUserWebs)

    const isMobile = screenWidth <= GlobalVars.MOBILE_SCREEN_WIDTH

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange)
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange)
        }
    }, [])

    useEffect(() => {
        document.addEventListener('keydown', handleEnterPress)
        return () => {
            document.removeEventListener('keydown', handleEnterPress)
        }
    })

    useEffect(() => {
        if (isLoggedIn) {
            if (userWebs.length > 0) navigate(Routes.campaign)
            else navigate(Routes.onboarding)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value)
    }

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value,
            pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            )

        if (pattern.test(newValue)) {
            setIsEmailValid(true)
        } else {
            setIsEmailValid(false)
        }

        setEmail(newValue)
    }

    const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value

        if (Number.parseInt(newValue) || newValue === '') {
            setPhoneNumber(newValue)
        }
    }

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value)
    }

    const handleRepeatedPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRepeatedPassword(event.target.value)
    }

    const handleEnterPress = (e: KeyboardEvent) => {
        if (e.key === 'Enter') onCreateSubmit()
    }

    const handleWindowSizeChange = () => {
        setScreenWidth(window.innerWidth)
    }

    const onLoginSubmit = async () => {
        try {
            dispatch(clearWebs())
            dispatch(removeUserInfo())
            const userManager = UserManager.getManager()
            const loggedUserInfo = await userManager.loginAsync(email, password)
            // Object "user" has all props from "loggedUserInfo" object, except "token" prop
            const { token, ...user } = loggedUserInfo
            if (user) dispatch(setUserInfo(user))
            localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, token)
            const state = parseJwtEncodedTokenAndGetReduxState(token)
            dispatch(setAuthState(state))
            navigate(Routes.onboarding)
        } catch (e: any) {
            showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
            setIsLoading(false)
        }
    }

    const onCreateSubmit = async () => {
        if (name.length === 0 || email.length === 0 || password.length === 0) {
            showFlashMessageWithTimeout(dispatch, 'Vyplňte všechna povinná pole', 'info')
            return
        }

        if (password !== repeatedPassword) {
            showFlashMessageWithTimeout(dispatch, 'Hesla se neshodují', 'info')
            return
        }

        if (!agreeWithTermsAndConditions) {
            showFlashMessageWithTimeout(dispatch, 'Je vyžadován souhlas s podmínkami', 'info')
            return
        }

        if (!isEmailValid) {
            showFlashMessageWithTimeout(dispatch, 'Zadejte validní email', 'info')
            return
        }

        setIsLoading(true)
        try {
            const _userManager = UserManager.getManager()
            const wholePhoneNumber = phoneNumber.length > 0 ? phoneNumberCode + phoneNumber : undefined
            const lang: Language = getLanguageFromString(i18n.language)
            const referralObject = Cookies.get('rewardful.referral')
            let referralId = ''
            if (referralObject) {
                const decoded: DecodedType = JSON.parse(decodeURI(referralObject))
                referralId = decoded.id
            }
            await _userManager.createUserAsync(email, password, name, lang, wholePhoneNumber, undefined, referralId)
            setIsLoading(false)
            onLoginSubmit()
        } catch (e: any) {
            showFlashMessageWithTimeout(dispatch, e.toString(), 'error')
            setIsLoading(false)
        }
    }

    // const clearForms = () => {
    //     setName('')
    //     setEmail('')
    //     setPassword('')
    //     setRepeatedPassword('')
    // }

    return (
        <>
            <Typography className={classes.mainTitleText}>
                {t('registrationScreen.title.first')}
                <u>{t('registrationScreen.title.second')}</u> {t('registrationScreen.title.third')}{' '}
                <u>{t('registrationScreen.title.fourth')}</u>
            </Typography>
            <Box className={classes.container}>
                {!isMobile && (
                    <Box className={classes.imgContainer}>
                        <img style={{ marginTop: 130 }} src={RegistrationPng} alt='registration' width={188} height={234} />
                    </Box>
                )}

                <Box className={classes.formContainer} style={{ marginLeft: isMobile ? 56 : 0 }}>
                    <Typography className={classes.titleText}>{t('registrationScreen.subTitle')}</Typography>
                    <Box style={{ display: 'flex', flexDirection: 'row', gap: 48 }}>
                        <CustomTextField
                            style={{ marginBottom: MARGIN_HIGHER }}
                            fullWidth
                            label={t('registrationScreen.name') ?? ''}
                            value={name}
                            onChange={handleNameChange}
                            isRequired
                        />
                    </Box>
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginBottom: MARGIN_HIGHER,
                        }}
                    >
                        <CustomDropdown
                            id={`phone-code-dropdown`}
                            value={phoneNumberCode}
                            items={[
                                {
                                    title: '+420',
                                    value: '+420',
                                },
                                {
                                    title: '+421',
                                    value: '+421',
                                },
                                {
                                    title: '+48',
                                    value: '+48',
                                },
                            ]}
                            onChange={(event) => setPhoneNumberCode(event.target.value)}
                            label={''}
                            width={120}
                        />
                        <TextField
                            label={t('registrationScreen.phone') ?? ''}
                            fullWidth
                            variant='outlined'
                            value={phoneNumber}
                            type='tel'
                            onChange={handlePhoneNumberChange}
                            style={{ height: 44 }}
                            InputProps={{
                                sx: { height: 44 },
                            }}
                            InputLabelProps={{
                                sx:
                                    phoneNumber.length === 0
                                        ? {
                                              marginTop: -0.5,
                                              '&.Mui-focused': {
                                                  marginTop: 0,
                                              },
                                              '&.Mui-disabled': {
                                                  marginTop: 0,
                                              },
                                          }
                                        : undefined,
                            }}
                        />
                    </Box>

                    <CustomTextField
                        style={{ marginBottom: MARGIN_HIGHER }}
                        fullWidth
                        label={t('registrationScreen.email') ?? ''}
                        value={email}
                        onChange={handleEmailChange}
                        isRequired
                    />
                    <PasswordField
                        label={t('registrationScreen.password') ?? ''}
                        value={password}
                        onChange={handlePasswordChange}
                        fullWidth
                        style={{ marginBottom: MARGIN_HIGHER }}
                        isRequired
                    />
                    <PasswordField
                        label={t('registrationScreen.confirmPassword') ?? ''}
                        value={repeatedPassword}
                        onChange={handleRepeatedPasswordChange}
                        fullWidth
                        style={{ marginBottom: MARGIN_SMALLER }}
                        isRequired
                    />
                    <FormGroup style={{ marginBottom: MARGIN_SMALLER }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={agreeWithTermsAndConditions}
                                    style={{
                                        color: theme.palette.main.primary.o,
                                        transform: 'scale(1.2)',
                                    }}
                                    onClick={() => setAgreeWithTermsAndConditions(!agreeWithTermsAndConditions)}
                                />
                            }
                            label={
                                <span>
                                    {t('registrationScreen.accept') ?? ''}{' '}
                                    <a
                                        href='https://www.overenyweb.cz/vseobecne-obchodni-podminky/'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        {t('registrationScreen.terms') ?? ''}
                                    </a>
                                </span>
                            }
                        />
                    </FormGroup>

                    <CustomButton
                        title={t('registrationScreen.button') ?? ''}
                        onClick={onCreateSubmit}
                        isLoading={isLoading}
                        color='#30A05C'
                        fontSize={isMobile ? 20 : 26}
                        style={{ marginBottom: 16 }}
                        isDisabled={
                            !agreeWithTermsAndConditions ||
                            !isEmailValid ||
                            email.length === 0 ||
                            name.length === 0 ||
                            password.length === 0 ||
                            repeatedPassword.length === 0
                        }
                    />
                    <Box className={classes.textContainer}>
                        <Typography className={classes.text}>{t('registrationScreen.alreadyHaveAccount') ?? ''}</Typography>
                        <Typography className={classes.textUnderline} onClick={() => navigate(Routes.login)}>
                            {t('registrationScreen.login') ?? ''}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    textContainer: {
        display: 'flex',
        marginBottom: 40,
    },
    text: {
        marginRight: 4,
        fontSize: 14,
        color: theme.palette.natural.t1,
    },
    textUnderline: {
        fontWeight: 'bold',
        color: theme.palette.main.secondary.u1,
        cursor: 'pointer',
        fontSize: 14,
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
    },
    imgContainer: {
        width: 342,
        display: 'flex',
        justifyContent: 'center',
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginRight: 56,
    },
    titleText: {
        fontSize: 15,
        paddingBottom: 12,
        fontWeight: 'bold',
        color: theme.palette.main.secondary.u1,
    },
    mainTitleText: {
        fontSize: 30,
        fontWeight: 'bold',
        padding: 30,
        paddingBottom: 12,
        textAlign: 'center',
    },
}))

export default RegistrationForm
